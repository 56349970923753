import React, { useState } from "react";
import "./TopPanelBase.css";
import { useNavigate } from "react-router-dom";

function TopPanelBase({ rightContent }) {
  const [language, setLanguage] = useState("eng");
  let navigate = useNavigate();

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  const handleClick = () => {
    navigate("/");
  };

  return (
    <div className="top_panel">
      <nav
        className="navbar-fixed"
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      >
        CollEmPoll
      </nav>

      <div className="nav-center-section"></div>

      <div className="language-selector">{rightContent}</div>
    </div>
  );
}

export default TopPanelBase;
