import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { reactUrl, serverUrl } from "../Utils/storage";
import { useNavigate, useLocation } from "react-router-dom";
import "./AdminPanel.css";
import TopPanelBase from "../Components/TopPanel/TopPanelBase";
import BotPanel from "../Components/BottomPanel/BotPanel";

const ADMIN_EMAILS = [
  "kolodnyi@vntu.edu.ua",
  "youremail@gmail.com",
  "youremail1@gmail.com", // Replace with your actual email
  // Add any other admin emails here
];

// Using online icons instead of local imports
const ICON_URLS = {
  edit: "https://cdn-icons-png.flaticon.com/512/1159/1159633.png",
  stats: "https://cdn-icons-png.flaticon.com/512/3643/3643948.png",
  copy: "https://cdn-icons-png.flaticon.com/512/1621/1621635.png",
  delete: "https://cdn-icons-png.flaticon.com/512/3405/3405244.png",
  user: "https://cdn-icons-png.flaticon.com/512/1077/1077114.png",
};

function AdminPanel() {
  const location = useLocation();
  const navigate = useNavigate();
  const contentRef = useRef(null);

  // Parse query parameters from URL
  const queryParams = new URLSearchParams(location.search);

  const [allSurveys, setAllSurveys] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [activeTab, setActiveTab] = useState(
    queryParams.get("tab") || "surveys"
  ); // Get from URL or default to "surveys"
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(queryParams.get("search") || ""); // Get from URL or default to empty
  const [adminInfo, setAdminInfo] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const authToken = localStorage.getItem("authToken");

  // Update URL when tab changes
  const handleTabChange = (tab) => {
    setActiveTab(tab);

    // Update URL with new tab
    const newParams = new URLSearchParams(location.search);
    newParams.set("tab", tab);
    navigate(`${location.pathname}?${newParams.toString()}`);
  };

  // Update URL when search term changes
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Update URL with new search term
    const newParams = new URLSearchParams(location.search);
    newParams.set("search", value);
    navigate(`${location.pathname}?${newParams.toString()}`);
  };

  // Restore scroll position when component mounts or tab changes
  useEffect(() => {
    // Try to restore scroll position from session storage
    const savedScrollPosition = sessionStorage.getItem(
      `adminScrollPosition-${activeTab}`
    );
    if (savedScrollPosition && contentRef.current) {
      setTimeout(() => {
        contentRef.current.scrollTop = parseInt(savedScrollPosition, 10);
      }, 100);
    }

    // Save scroll position when unmounting or changing tabs
    return () => {
      if (contentRef.current) {
        sessionStorage.setItem(
          `adminScrollPosition-${activeTab}`,
          contentRef.current.scrollTop.toString()
        );
      }
    };
  }, [activeTab]);

  // Fetch admin profile info and check admin status
  useEffect(() => {
    async function checkAdminStatus() {
      try {
        const response = await fetch(serverUrl + "/user/profile", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          setAdminInfo(data);
          console.log(
            "admin panel ",
            data.email,
            ADMIN_EMAILS.includes(data.email.toLowerCase())
          );
          if (data.email && ADMIN_EMAILS.includes(data.email.toLowerCase())) {
            console.log("Is admin in AdminPanel:", data.email);
            setIsAdmin(true);
          } else {
            alert("You don't have permission to access this page");
            navigate("/logged-in");
          }
        } else {
          console.error("Failed to fetch admin profile:", response.statusText);
          navigate("/login");
        }
      } catch (error) {
        console.error("Error checking admin status:", error.message);
        navigate("/login");
      }
    }

    checkAdminStatus();
  }, [authToken, navigate]);

  // Fetch data based on active tab
  useEffect(() => {
    if (!isAdmin) return;

    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (activeTab === "surveys") {
          // Fetch all surveys (not just the admin's)
          const response = await axios.get(`${serverUrl}/admin/surveys`, {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          });
          setAllSurveys(response.data);
        } else if (activeTab === "users") {
          // Fetch all users
          const response = await axios.get(`${serverUrl}/admin/users`, {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          });
          setAllUsers(response.data);
        }
      } catch (error) {
        console.error(`Error fetching ${activeTab}:`, error);
        if (error.response && error.response.status === 403) {
          alert("You don't have permission to access this resource");
          navigate("/logged-in");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [activeTab, authToken, isAdmin, navigate]);

  const copyToClipboard = (text) => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert("Link copied to clipboard: " + text);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      try {
        let textarea = document.createElement("textarea");
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        let successful = document.execCommand("copy");
        if (successful) {
          alert("Link copied to clipboard!");
        } else {
          throw new Error("Copy command was unsuccessful");
        }
        document.body.removeChild(textarea);
      } catch (err) {
        console.error("Fallback: Unable to copy", err);
      }
    }
  };

  const deleteSurvey = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this survey? This action cannot be undone."
    );
    if (!confirmed) return;

    try {
      const response = await axios.get(
        `${serverUrl}/admin/survey/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        alert("Survey deleted successfully");
        // Refresh the survey list
        setAllSurveys(allSurveys.filter((survey) => survey.survey_id !== id));
      } else {
        console.error("Failed to delete survey:", response.statusText);
        alert("Failed to delete survey");
      }
    } catch (error) {
      console.error("Error deleting survey:", error.message);
      alert("An error occurred while deleting the survey");
    }
  };

  const deleteUser = async (userId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this user? This will also delete all their surveys and responses."
    );
    if (!confirmed) return;

    try {
      const response = await axios.delete(
        `${serverUrl}/admin/user/delete/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        alert("User deleted successfully");
        // Refresh the user list
        setAllUsers(allUsers.filter((user) => user.user_id !== userId));
      } else {
        console.error("Failed to delete user:", response.statusText);
        alert("Failed to delete user");
      }
    } catch (error) {
      console.error("Error deleting user:", error.message);
      alert("An error occurred while deleting the user");
    }
  };

  const navigateTo = (url) => {
    // Save current state before navigating
    if (contentRef.current) {
      sessionStorage.setItem(
        `adminScrollPosition-${activeTab}`,
        contentRef.current.scrollTop.toString()
      );
    }

    // If navigating to user surveys, add the current tab and search term as parameters
    if (url.startsWith("/admin/user-surveys/")) {
      url = `${url}?returnTo=/admin&returnTab=${activeTab}&returnSearch=${encodeURIComponent(
        searchTerm
      )}`;
    }

    navigate(url);
  };

  // Filter surveys or users based on search term
  const filteredSurveys = allSurveys.filter(
    (survey) =>
      survey.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (survey.creator_name &&
        survey.creator_name.toLowerCase().includes(searchTerm.toLowerCase()))
  );
  console.log("filteredSurveys", filteredSurveys);
  const filteredUsers = allUsers.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (!isAdmin) {
    return <div>Checking permissions...</div>;
  }

  return (
    <div className="base-unfixed-footer">
      <div className="base-unfixed-footer-content">
        <div className="admin-panel-page">
          <TopPanelBase />

          <div className="admin-panel-top">
            <h1>Admin Panel</h1>
            <p>
              {adminInfo ? `Administrator: ${adminInfo.name}` : "Loading..."}
            </p>

            <div className="admin-tabs">
              <button
                onClick={() => handleTabChange("surveys")}
                className={`admin-tab-button ${
                  activeTab === "surveys" ? "active" : ""
                }`}
              >
                Manage All Surveys
              </button>
              <button
                onClick={() => handleTabChange("users")}
                className={`admin-tab-button ${
                  activeTab === "users" ? "active" : ""
                }`}
              >
                Manage Users
              </button>
            </div>

            <div className="admin-search">
              <input
                type="text"
                placeholder={`Search ${
                  activeTab === "surveys" ? "surveys" : "users"
                }...`}
                value={searchTerm}
                onChange={handleSearchChange}
                className="admin-search-input"
              />
            </div>
          </div>

          {isLoading ? (
            <div className="admin-loading">Loading data...</div>
          ) : (
            <div className="admin-content" ref={contentRef}>
              {activeTab === "surveys" && (
                <div className="admin-surveys">
                  <h2>All Surveys ({filteredSurveys.length})</h2>
                  {filteredSurveys.length === 0 ? (
                    <p className="no-data-message">No surveys found</p>
                  ) : (
                    <ul className="admin-list">
                      {filteredSurveys.map((survey) => (
                        <li key={survey.survey_id} className="admin-list-item">
                          <div className="admin-item-details">
                            <h3>{survey.title}</h3>
                            <p>
                              Created by: {survey.creator_name || "Unknown"}
                            </p>
                            <p>
                              Created on:{" "}
                              {new Date(
                                survey.createdAt || survey.createdAt
                              ).toLocaleDateString()}
                            </p>
                            <p>Responses: {survey.response_count || 0}</p>
                          </div>
                          <div className="admin-item-actions">
                            <button
                              className="admin-action-button"
                              onClick={() =>
                                copyToClipboard(
                                  `${reactUrl}/survey/${survey.hash}`
                                )
                              }
                            >
                              <img
                                src={ICON_URLS.copy}
                                alt="Copy"
                                className="admin-icon"
                              />
                              <span>Copy Link</span>
                            </button>
                            <button
                              className="admin-action-button"
                              onClick={() =>
                                navigateTo(`/edit-survey/${survey.survey_id}`)
                              }
                            >
                              <img
                                src={ICON_URLS.edit}
                                alt="Edit"
                                className="admin-icon"
                              />
                              <span>Edit</span>
                            </button>
                            <button
                              className="admin-action-button"
                              onClick={() =>
                                navigateTo(
                                  `/admin/survey-results/${survey.survey_id}`
                                )
                              }
                            >
                              <img
                                src={ICON_URLS.stats}
                                alt="Results"
                                className="admin-icon"
                              />
                              <span>Results</span>
                            </button>
                            <button
                              className="admin-action-button delete"
                              onClick={() => deleteSurvey(survey.survey_id)}
                            >
                              <img
                                src={ICON_URLS.delete}
                                alt="Delete"
                                className="admin-icon"
                              />
                              <span>Delete</span>
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}

              {activeTab === "users" && (
                <div className="admin-users">
                  <h2>All Users ({filteredUsers.length})</h2>
                  {filteredUsers.length === 0 ? (
                    <p className="no-data-message">No users found</p>
                  ) : (
                    <ul className="admin-list">
                      {filteredUsers.map((user) => (
                        <li key={user.user_id} className="admin-list-item">
                          <div className="admin-item-details">
                            <h3>{user.name}</h3>
                            <p>Email: {user.email}</p>
                            <p>
                              Joined:{" "}
                              {new Date(
                                user.createdAt || user.createdAt
                              ).toLocaleDateString()}
                            </p>
                            <p>Surveys Created: {user.surveys_count || 0}</p>
                            <p>Role: {user.role || "User"}</p>
                          </div>
                          <div className="admin-item-actions">
                            <button
                              className="admin-action-button"
                              onClick={() =>
                                navigateTo(
                                  `/admin/user-surveys/${user.user_id}`
                                )
                              }
                            >
                              <img
                                src={ICON_URLS.stats}
                                alt="Surveys"
                                className="admin-icon"
                              />
                              <span>View Surveys</span>
                            </button>
                            {!user.isAdmin && (
                              <button
                                className="admin-action-button delete"
                                onClick={() => deleteUser(user.user_id)}
                              >
                                <img
                                  src={ICON_URLS.delete}
                                  alt="Delete"
                                  className="admin-icon"
                                />
                                <span>Delete User</span>
                              </button>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <BotPanel is_fixed={false} />
    </div>
  );
}

export default AdminPanel;
