import React from "react";
import TopPanelBase from "../Components/TopPanel/TopPanelBase";
import BotPanel from "../Components/BottomPanel/BotPanel";
import { useNavigate } from "react-router-dom";
import "./HelpPage.css";

function HelpPage() {
  const navigate = useNavigate();

  const handleBackToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="HelpPage">
      <TopPanelBase />

      <div className="help-container">
        <h1>Довідка користувача</h1>

        <div className="help-section">
          <h2>Реєстрація та авторизація</h2>
          <div className="help-item">
            <h3>Створення облікового запису</h3>
            <p>Для створення нового облікового запису:</p>
            <ol>
              <li>На сторінці входу натисніть "Потрібен акаунт? Sign up"</li>
              <li>
                Заповніть форму: введіть своє ім'я, електронну пошту та пароль
              </li>
              <li>Натисніть кнопку "Create Account"</li>
            </ol>
          </div>

          <div className="help-item">
            <h3>Вхід в систему</h3>
            <p>Для входу у свій обліковий запис:</p>
            <ol>
              <li>На сторінці входу введіть свою електронну пошту та пароль</li>
              <li>Натисніть кнопку "Login"</li>
            </ol>
          </div>

          <div className="help-item">
            <h3>Відновлення паролю</h3>
            <p>Якщо ви забули пароль:</p>
            <ol>
              <li>Натисніть "Forgot password?" на екрані входу</li>
              <li>
                Введіть електронну адресу, прив'язану до вашого облікового
                запису
              </li>
              <li>Натисніть кнопку "Send Reset Link"</li>
              <li>
                Перевірте вашу електронну пошту та дотримуйтесь інструкцій для
                скидання паролю
              </li>
            </ol>
          </div>
        </div>

        <div className="help-section">
          <h2>Створення опитування</h2>
          <div className="help-item">
            <h3>Як створити нове опитування</h3>
            <p>Для створення опитування:</p>
            <ol>
              <li>
                Увійдіть в систему та перейдіть до розділу "Create Survey"
              </li>
              <li>Введіть назву та опис опитування</li>
              <li>
                Додайте запитання, обравши потрібний тип (з одним варіантом
                відповіді, з багатьма варіантами, текстова відповідь)
              </li>
              <li>Додайте варіанти відповідей для кожного питання</li>
              <li>Натисніть кнопку "Save" для збереження опитування</li>
            </ol>
          </div>
        </div>

        <div className="help-section">
          <h2>Керування опитуваннями</h2>
          <div className="help-item">
            <h3>Перегляд створених опитувань</h3>
            <p>Для перегляду створених вами опитувань:</p>
            <ol>
              <li>Увійдіть в систему</li>
              <li>Перейдіть до розділу "My Surveys"</li>
              <li>Тут ви побачите список усіх ваших опитувань</li>
            </ol>
          </div>

          <div className="help-item">
            <h3>Редагування опитування</h3>
            <p>Для редагування існуючого опитування:</p>
            <ol>
              <li>У розділі "My Surveys" знайдіть потрібне опитування</li>
              <li>Натисніть кнопку "Edit" поруч із опитуванням</li>
              <li>Внесіть необхідні зміни та натисніть "Save"</li>
            </ol>
          </div>

          <div className="help-item">
            <h3>Перегляд результатів</h3>
            <p>Для перегляду результатів опитування:</p>
            <ol>
              <li>У розділі "My Surveys" знайдіть потрібне опитування</li>
              <li>Натисніть кнопку "Results" поруч із опитуванням</li>
              <li>
                Ви побачите узагальнені результати та відповіді окремих
                користувачів
              </li>
            </ol>
          </div>
        </div>

        <div className="help-section">
          <h2>Проходження опитування</h2>
          <div className="help-item">
            <h3>Як пройти опитування</h3>
            <p>Для проходження опитування:</p>
            <ol>
              <li>Перейдіть за посиланням, яке ви отримали</li>
              <li>Прочитайте інструкції та дайте відповіді на запитання</li>
              <li>Натисніть кнопку "Submit" для відправки ваших відповідей</li>
            </ol>
          </div>
        </div>

        <div className="help-section">
          <h2>Технічна підтримка</h2>
          <div className="help-item">
            <p>
              Якщо у вас виникли проблеми з використанням сервісу, зв'яжіться з
              нами:
            </p>
            <p className="help-contact">supportcollempoll@gmail.com</p>
          </div>
        </div>

        <button className="help-back-button" onClick={handleBackToLogin}>
          Повернутися до входу
        </button>
      </div>

      <BotPanel is_fixed={true} />
    </div>
  );
}

export default HelpPage;
