import React, { useEffect, useState } from "react";
import axios from "axios";
//import { Link } from 'react-router-dom'; // Додаємо Link для навігації
import { reactUrl, serverUrl } from "../Utils/storage";
import { useNavigate } from "react-router-dom";

//-- Top panel
import "./mySurvey.css";
import TopPanelBase from "../Components/TopPanel/TopPanelBase";

//-- Icons
import edit_icon from "../icons/edit_icon.png";
import stats_icon from "../icons/stats_icon.png";
import copy_icon from "../icons/copy_icon.png";

import BotPanel from "../Components/BottomPanel/BotPanel";

function MySurvey() {
  const [activeTab, setActiveTab] = useState("created"); // 'created' or 'taken'
  const [createdSurveys, setCreatedSurveys] = useState([]);
  const [takenSurveys, setTakenSurveys] = useState([]);
  const [userInfo, setUserInfo] = useState(null);

  let navigate = useNavigate();

  // Fetching data based on the active tab
  const authToken = localStorage.getItem("authToken");

  const fetchData = async () => {
    try {
      if (activeTab === "created") {
        const response = await axios.get(`${serverUrl}/survey/created`, {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include bearer token in the headers
          },
        });
        setCreatedSurveys(response.data); // Assuming response.data contains the surveys
      } else {
        const response = await axios.get(`${serverUrl}/survey/taken`, {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include bearer token in the headers
          },
        });
        setTakenSurveys(response.data); // Assuming response.data contains the surveys
      }
    } catch (error) {
      console.error("Error fetching surveys:", error);
    }
  };

  useEffect(() => {
    async function fetchUserProfile() {
      try {
        const response = await fetch(serverUrl + "/user/profile", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          console.log("uerInfo:", data);
          setUserInfo(data);
        } else {
          console.error("Failed to fetch user profile:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching user profile:", error.message);
      }
    }

    fetchData();
    fetchUserProfile();
  }, [activeTab, authToken]);

  const copyToClipboard = (text) => {
    // New Clipboard API
    if (navigator.clipboard && window.isSecureContext) {
      // If the Clipboard API is available
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert("Link copied to clipboard! " + text);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      // Fallback: Copying text using a temporary textarea
      try {
        let textarea = document.createElement("textarea");
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        let successful = document.execCommand("copy");
        if (successful) {
          alert("Link copied to clipboard!");
        } else {
          throw new Error("Copy command was unsuccessful");
        }
        document.body.removeChild(textarea);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
    }
  };
  const deleteSurvey = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this survey?"
    );
    if (!confirmed) return;

    try {
      const response = await axios.get(`${serverUrl}/survey/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        console.log("Survey deleted:", response.data);
        alert("Survey deleted successfully");
        fetchData(); // Refresh the survey list
        // Optionally, refresh the survey list or update the state to remove the deleted survey
      } else {
        console.error("Failed to delete survey:", response.statusText);
        alert("Failed to delete survey");
      }
    } catch (error) {
      console.error("Error deleting survey:", error.message);
      alert("An error occurred while deleting the survey");
    }
  };

  const navigateTo = (url) => {
    navigate(url);
  };

  return (
    <div className="base-unfixed-footer">
      <div className="base-unfixed-footer-content">
        <div className="my-survey-page">
          <TopPanelBase />
          <div className="my-survey-top">
            <h1>My EmPolls</h1>
            <p>
              {userInfo ? userInfo.name : "User"}, you can see your Polls below:
            </p>

            <button
              onClick={() => setActiveTab("created")}
              style={{
                backgroundColor:
                  activeTab === "created" ? "#347BE2" : "#2050A5",
                color: "white",
                padding: "10px",
                margin: "5px",
                border: "none",
                borderRadius: "5px",
              }}
            >
              My Created EmPolls
            </button>
            <button
              onClick={() => setActiveTab("taken")}
              style={{
                backgroundColor: activeTab === "created" ? "#2050A5" : "#347BE2",
                color: "white",
                padding: "10px",
                margin: "5px",
                border: "none",
                borderRadius: "5px",
              }}
            >
              My Taken EmPolls
            </button>
          </div>

          <div className="my-survey-all">
            {activeTab === "created" && (
              <ul>
                {createdSurveys.map((survey, index) => (
                  <li key={survey.survey_id}>
                    <div className="my-survey-all-unit">
                      <p>"{survey.title}"</p>
                      <div className="my-survey-all-unit-buttons">
                        <button
                          className="base-iconed-button"
                          onClick={() =>
                            copyToClipboard(`${reactUrl}/survey/${survey.hash}`)
                          }
                        >
                          Copy
                          <img
                            src={copy_icon}
                            alt="copy-survey-link"
                            className="icon"
                          />
                        </button>

                        <button
                          className="base-iconed-button"
                          onClick={() =>
                            navigateTo(`/edit-survey/${survey.survey_id}`)
                          }
                        >
                          Edit
                          <img
                            src={edit_icon}
                            alt="edit-survey"
                            className="icon"
                          />
                        </button>

                        <button
                          className="base-iconed-button"
                          onClick={() =>
                            navigateTo(
                              `/survey-results-admin/${survey.survey_id}`
                            )
                          }
                        >
                          Results
                          <img
                            src={stats_icon}
                            alt="results-survey"
                            className="icon"
                          />
                        </button>
                        <button
                          className="base-iconed-button"
                          onClick={() => deleteSurvey(survey.survey_id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}

            {activeTab === "taken" && (
              <ul>
                {takenSurveys.map((taken, index) => (
                  <div className="my-survey-all-unit">
                    <li key={taken.survey_id}>
                      <p>"{taken.title}"</p>
                      <div className="my-survey-all-unit-buttons">
                        <button
                          className="base-iconed-button"
                          onClick={() =>
                            navigateTo(`/survey-results/${taken.survey_id}`)
                          }
                        >
                          Results
                          <img
                            src={stats_icon}
                            alt="my-results-survey"
                            className="icon"
                          />
                        </button>
                      </div>
                    </li>
                  </div>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
      <BotPanel is_fixed={false} />
    </div>
  );
}

export default MySurvey;
