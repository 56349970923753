import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { reactUrl, serverUrl } from "../Utils/storage";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "./AdminPanel.css"; // Reuse admin panel styles
import TopPanelBase from "../Components/TopPanel/TopPanelBase";
import BotPanel from "../Components/BottomPanel/BotPanel";

const ADMIN_EMAILS = [
  "kolodnyi@vntu.edu.ua",
  "youremail@gmail.com", // Replace with your actual email
  "youremail1@gmail.com", // Replace with your actual email

  // Add any other admin emails here
];

const ICON_URLS = {
  edit: "https://cdn-icons-png.flaticon.com/512/1159/1159633.png",
  stats: "https://cdn-icons-png.flaticon.com/512/3643/3643948.png",
  copy: "https://cdn-icons-png.flaticon.com/512/1621/1621635.png",
  delete: "https://cdn-icons-png.flaticon.com/512/3405/3405244.png",
  back: "https://cdn-icons-png.flaticon.com/512/271/271220.png",
};

function UserSurveysAdmin() {
  const { userId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const contentRef = useRef(null);

  // Parse the URL search parameters
  const queryParams = new URLSearchParams(location.search);
  const returnTo = queryParams.get("returnTo") || "/admin"; // Get return path or default to /admin
  const returnTab = queryParams.get("returnTab") || "users"; // Get return tab or default to users
  const returnSearch = queryParams.get("returnSearch") || ""; // Get return search term or default to empty

  const [userSurveys, setUserSurveys] = useState([]);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const authToken = localStorage.getItem("authToken");

  // Restore scroll position when component mounts
  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem(
      `userSurveysAdminScrollPosition-${userId}`
    );
    if (savedScrollPosition && contentRef.current) {
      setTimeout(() => {
        contentRef.current.scrollTop = parseInt(savedScrollPosition, 10);
      }, 100);
    }

    // Save scroll position when unmounting
    return () => {
      if (contentRef.current) {
        sessionStorage.setItem(
          `userSurveysAdminScrollPosition-${userId}`,
          contentRef.current.scrollTop.toString()
        );
      }
    };
  }, [userId]);

  // Check admin status
  useEffect(() => {
    async function checkAdminStatus() {
      try {
        const response = await fetch(serverUrl + "/user/profile", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          console.log('admin survey ', data.email, ADMIN_EMAILS.includes(data.email.toLowerCase()))
          if (data.email && ADMIN_EMAILS.includes(data.email.toLowerCase())) {
            console.log("Is admin in UserSurveysAdmin:", data.email);
            setIsAdmin(true);
          } else {
            alert("You don't have permission to access this page");
            navigate("/logged-in");
          }
        } else {
          console.error("Failed to fetch admin profile:", response.statusText);
          navigate("/login");
        }
      } catch (error) {
        console.error("Error checking admin status:", error.message);
        navigate("/login");
      }
    }

    checkAdminStatus();
  }, [authToken, navigate]);

  // Fetch user data and surveys
  useEffect(() => {
    if (!isAdmin) return;

    const fetchUserAndSurveys = async () => {
      setIsLoading(true);
      try {
        // Fetch user details
        const userResponse = await axios.get(
          `${serverUrl}/admin/user/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setUserData(userResponse.data);

        // Fetch user's surveys
        const surveyResponse = await axios.get(
          `${serverUrl}/admin/user/${userId}/surveys`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setUserSurveys(surveyResponse.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
        if (error.response && error.response.status === 403) {
          alert("You don't have permission to access this resource");
          navigate("/admin");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserAndSurveys();
  }, [userId, authToken, isAdmin, navigate]);

  const copyToClipboard = (text) => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert("Link copied to clipboard: " + text);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      try {
        let textarea = document.createElement("textarea");
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        let successful = document.execCommand("copy");
        if (successful) {
          alert("Link copied to clipboard!");
        } else {
          throw new Error("Copy command was unsuccessful");
        }
        document.body.removeChild(textarea);
      } catch (err) {
        console.error("Fallback: Unable to copy", err);
      }
    }
  };

  const deleteSurvey = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this survey? This action cannot be undone."
    );
    if (!confirmed) return;

    try {
      const response = await axios.get(
        `${serverUrl}/admin/survey/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        alert("Survey deleted successfully");
        // Refresh the survey list
        setUserSurveys(userSurveys.filter((survey) => survey.survey_id !== id));
      } else {
        console.error("Failed to delete survey:", response.statusText);
        alert("Failed to delete survey");
      }
    } catch (error) {
      console.error("Error deleting survey:", error.message);
      alert("An error occurred while deleting the survey");
    }
  };

  const goBack = () => {
    // Return to admin page with previously selected tab and search term preserved
    navigate(`${returnTo}?tab=${returnTab}&search=${returnSearch}`);
  };

  const navigateTo = (url) => {
    // Save scroll position before navigating away
    if (contentRef.current) {
      sessionStorage.setItem(
        `userSurveysAdminScrollPosition-${userId}`,
        contentRef.current.scrollTop.toString()
      );
    }
    navigate(url);
  };

  // Filter surveys based on search term
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredSurveys = userSurveys.filter((survey) =>
    survey.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (!isAdmin) {
    return <div>Checking permissions...</div>;
  }

  return (
    <div className="base-unfixed-footer">
      <div className="base-unfixed-footer-content">
        <div className="admin-panel-page">
          <TopPanelBase />

          <div className="admin-panel-top">
            <button className="admin-back-button" onClick={goBack}>
              <img src={ICON_URLS.back} alt="Back" className="admin-icon" />
              Back to Admin Panel
            </button>

            <h1>User Surveys</h1>
            {userData ? (
              <div className="user-info">
                <h2> {userData.name}</h2>
                <p>  Email: {userData.email}</p>
                <p>
                  Account created:{" "}
                  {new Date(
                    userData.createdAt || userData.createdAt
                  ).toLocaleDateString()}
                </p>
              </div>
            ) : (
              <p>Loading user information...</p>
            )}

            <div className="admin-search">
              <input
                type="text"
                placeholder="Search surveys..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="admin-search-input"
              />
            </div>
          </div>

          {isLoading ? (
            <div className="admin-loading">Loading data...</div>
          ) : (
            <div className="admin-content" ref={contentRef}>
              <div className="admin-surveys">
                <h2>Surveys Created by This User ({filteredSurveys.length})</h2>
                {filteredSurveys.length === 0 ? (
                  <p className="no-data-message">
                    {searchTerm
                      ? "No surveys found matching your search"
                      : "This user hasn't created any surveys yet."}
                  </p>
                ) : (
                  <ul className="admin-list">
                    {filteredSurveys.map((survey) => (
                      <li key={survey.survey_id} className="admin-list-item">
                        <div className="admin-item-details">
                          <h3>{survey.title}</h3>
                          <p>
                            Created on:{" "}
                            {new Date(
                              survey.createdAt || survey.createdAt
                            ).toLocaleDateString()}
                          </p>
                          <p>Responses: {survey.response_count || 0}</p>
                        </div>
                        <div className="admin-item-actions">
                          <button
                            className="admin-action-button"
                            onClick={() =>
                              copyToClipboard(
                                `${reactUrl}/survey/${survey.hash}`
                              )
                            }
                          >
                            <img
                              src={ICON_URLS.copy}
                              alt="Copy"
                              className="admin-icon"
                            />
                            <span>Copy Link</span>
                          </button>
                          <button
                            className="admin-action-button"
                            onClick={() =>
                              navigateTo(`/edit-survey/${survey.survey_id}`)
                            }
                          >
                            <img
                              src={ICON_URLS.edit}
                              alt="Edit"
                              className="admin-icon"
                            />
                            <span>Edit</span>
                          </button>
                          <button
                            className="admin-action-button"
                            onClick={() =>
                              navigateTo(
                                `/admin/survey-results/${survey.survey_id}`
                              )
                            }
                          >
                            <img
                              src={ICON_URLS.stats}
                              alt="Results"
                              className="admin-icon"
                            />
                            <span>Results</span>
                          </button>
                          <button
                            className="admin-action-button delete"
                            onClick={() => deleteSurvey(survey.survey_id)}
                          >
                            <img
                              src={ICON_URLS.delete}
                              alt="Delete"
                              className="admin-icon"
                            />
                            <span>Delete</span>
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <BotPanel is_fixed={false} />
    </div>
  );
}

export default UserSurveysAdmin;
