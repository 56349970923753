import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { serverUrl } from "../Utils/storage";
import Question from "./Question";
import QuestionLeft from "./QuestionLeft";
import QuestionRight from "./QuestionRight";
import ChartComponent from "./ChartComponent";
import { generateColors } from "./ChartComponent";

import "./resultsSurveLocalAdmin.css";
import TopPanelBase from "../Components/TopPanel/TopPanelBase";
import BotPanel from "../Components/BottomPanel/BotPanel";
import SoulMate from "./SoulMate";
import QuestionLeftSoulMate from "./QuestionLefSoulMate";

const SurveyResults = () => {
  const { id } = useParams(); // Отримуємо id опитування з URL
  const [userId, setUserId] = useState(null); // Use state for user ID instead of URL params
  const [data, setData] = useState(null);
  const [similar, setSimilar] = useState(null);
  const [soulmate, setSoul] = useState(null);
  const [spearmen, setSp] = useState(null);
  const [questionSpearman, setQuestionSpearman] = useState({}); // New state for question-specific spearman data
  const [loading, setLoading] = useState(true);
  const [respCount, setRespCounter] = useState(null);

  // Fetch user ID from server first
  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const response = await axios.get(`${serverUrl}/auth/check-auth`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });

        if (response.data && response.data.userId) {
          setUserId(response.data.userId);
        } else {
          console.error("Could not get user ID from authentication");
        }
      } catch (error) {
        console.error("Error fetching user authentication:", error);
      }
    };

    fetchUserId();
  }, []);

  // Once we have the user ID, fetch all the necessary data
  useEffect(() => {
    if (!userId) return; // Don't proceed if userId is not available yet

    setLoading(true); // Set loading state while fetching data

    // Отримуємо дані результатів при завантаженні компонента
    const fetchResults = async () => {
      try {
        const response = await axios.get(
          `${serverUrl}/survey/${id}/results/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Додаємо токен авторизації у заголовки
            },
          }
        );
        setData(response.data);

        // After we get questions data, fetch spearman for each question
        if (response.data && response.data.questions) {
          await fetchQuestionSpearman(response.data.questions);
        }
      } catch (error) {
        console.error("Error fetching survey results:", error);
      }
    };

    const fetchQuestionSpearman = async (questions) => {
      try {
        const spearmanData = {};
        const promises = questions.map(async (question) => {
          try {
            const response = await axios.get(
              `${serverUrl}/survey/spearmen/${id}/${userId}/question/${question.questionId}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
              }
            );
            spearmanData[question.questionId] = response.data;
          } catch (error) {
            console.error(
              `Error fetching spearman for question ${question.questionId}:`,
              error
            );
            // Set a default value if there's an error
            spearmanData[question.questionId] = { spearmanCorrelation: 0 };
          }
        });

        await Promise.all(promises);
        console.log("Spearman data for questions:", spearmanData);
        setQuestionSpearman(spearmanData);
      } catch (error) {
        console.error("Error in fetchQuestionSpearman:", error);
      }
    };

    const fetchSoulMate = async () => {
      try {
        const response = await axios.get(
          `${serverUrl}/survey/soulmate/${id}/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Додаємо токен авторизації у заголовки
            },
          }
        );
        setSoul(response.data);
      } catch (error) {
        console.error("Error fetching survey results:", error);
      }
    };

    const fetchSpearmen = async () => {
      try {
        const response = await axios.get(
          `${serverUrl}/survey/spearmen/${id}/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Додаємо токен авторизації у заголовки
            },
          }
        );
        setSp(response.data);
      } catch (error) {
        console.error("Error fetching survey results:", error);
      }
    };

    const fetchRespCount = async () => {
      try {
        const response = await axios.get(
          `${serverUrl}/survey/resp-count/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Додаємо токен авторизації у заголовки
            },
          }
        );
        setRespCounter(response.data);
      } catch (error) {
        console.error("Error fetching survey resp count :", error);
      }
    };

    const fetchSimilar = async () => {
      try {
        const response = await axios.get(
          `${serverUrl}/survey/${id}/all-without-del/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Додаємо токен авторизації у заголовки
            },
          }
        );
        setSimilar(response.data);
      } catch (error) {
        console.error("Error fetching survey results:", error);
      } finally {
        setLoading(false); // End loading state after all fetches
      }
    };

    // Execute all fetch functions
    Promise.all([
      fetchResults(),
      fetchSoulMate(),
      fetchSpearmen(),
      fetchRespCount(),
      fetchSimilar(),
    ]);

    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href =
      "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css";
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, [id, userId]); // Now this effect depends on both id and userId

  if (loading || !userId || !data || !similar || !soulmate || !spearmen) {
    return <div>Loading results...</div>;
  }

  function getCorrelationMessage(spResult) {
    if (spResult > 0.8) {
      return "Hello, Soulmate! ";
    } else if (spResult > 0.5) {
      return "Candidate for a Soulmate ";
    } else if (spResult > 0.2) {
      return "";
    }
    return "Too few responses. "; // or any default text if needed
  }
  const findThreeMaxValuesAndIndices = (responses) => {
    const values = responses.map((resp) => resp.value);

    // Create an array of value-index pairs
    const valueIndexPairs = values.map((value, index) => ({ value, index }));

    // Sort by value descending
    const sortedPairs = valueIndexPairs.sort((a, b) => b.value - a.value);

    // Take the top 3 values and their indices
    const topTwo = sortedPairs.slice(0, 2);

    const maxValues = topTwo.map((pair) => pair.value);
    const maxIndices = topTwo.map((pair) => pair.index);

    return { maxValues, maxIndices };
  };
  const areIndicesEqualUnordered = (indices1, indices2) => {
    // Check if both arrays have the same length
    if (indices1.length !== indices2.length) {
      return false;
    }

    // Create sets from both arrays and compare their contents
    const set1 = new Set(indices1);
    const set2 = new Set(indices2);

    // Compare the two sets
    return [...set1].every((index) => set2.has(index));
  };

  return (
    <div style={{ width: "100%" }}>
      <Container className="result-admin-survey-main">
        <div className="result-admin-survey-top">
          <h1>Poll Results</h1>
          <h2 style={{ fontSize: "30px" }}>{data.survey.title}</h2>
          <p>{data.survey.description}</p>
        </div>

        <h3>Questions</h3>
        <div className="result-admin-survey-ser">
          {data.questions.map((question, index) => {
            /*const responsesLeft = question.responses;*/
            let responsesLeft = [];
            question.responses.forEach((resp) => {
              responsesLeft.push(resp.value);
            });
            const responsesRight = similar["avgValue"][question.questionId];

            const colors = generateColors(question.emotions);

            // Get question-specific correlation if available, otherwise fall back to global correlation
            const questionData = questionSpearman[question.questionId];
            // Add null check before accessing spearmanCorrelation
            const spResult =
              questionData && questionData.spearmanCorrelation
                ? questionData.spearmanCorrelation
                : 0;

            const message = getCorrelationMessage(spResult);

            return (
              <div key={question.questionId} className="mb-4">
                <div
                  key={question.questionId}
                  className="survey-result-container"
                >
                  <Question question={question} />

                  <div className="affinity-banner">
                    <h3>
                      <span
                        className={`affinity-label ${
                          spResult > 0.8 ? "high-affinity" : ""
                        }`}
                      >
                        {message}
                      </span>
                      {spResult >= 0.2 && (
                        <span className="affinity-value">
                          Emotional affinity:{" "}
                          <span
                            className={spResult > 0.8 ? "high-affinity" : ""}
                          >
                            {Math.round(spResult * 100)}%
                          </span>
                        </span>
                      )}
                      <span className="affinity-share">
                        Please share this survey!
                      </span>
                    </h3>
                  </div>

                  <div className="questions-comparison">
                    <div className="question-box left-question">
                      <QuestionLeft question={question} />
                    </div>
                    {spResult >= 0.2 && (
                      <div className="question-box right-question">
                        <QuestionLeft
                          question={
                            spearmen &&
                            spearmen.surveyResults &&
                            spearmen.surveyResults.questions &&
                            spearmen.surveyResults.questions[index]
                              ? spearmen.surveyResults.questions[index]
                              : question
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>

                <br></br>
                <h2>Difference between your and collective response</h2>
                <Row>
                  <Col xs={6}>
                    <QuestionLeft question={question} />
                  </Col>
                  {spResult >= 0.2 && (
                    <Col xs={6}>
                      <QuestionLeftSoulMate
                        question={question}
                        questionSoulMate={responsesRight}
                      />
                    </Col>
                  )}
                </Row>
                <br></br>
                <h2>Your and Collective response</h2>
                <Row>
                  <Col xs={6}>
                    <ChartComponent
                      responses={responsesLeft}
                      emotions={question.emotions}
                      colors={colors}
                    />
                  </Col>

                  <Col xs={6}>
                    <ChartComponent
                      responses={responsesRight}
                      emotions={question.emotions}
                      colors={colors}
                    />
                  </Col>
                </Row>
                <br></br>
              </div>
            );
          })}
        </div>
      </Container>

      <TopPanelBase />
      <BotPanel is_fixed={false} />
    </div>
  );
};
export default SurveyResults;
