import React, { useState } from 'react';
import '../styles/App.css';
import { defaultEmotions, serverUrl } from '../Utils/storage';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './createSyrvey.css';
import TopPanelBase from '../Components/TopPanel/TopPanelBase';
import BotPanel from '../Components/BottomPanel/BotPanel';

function generateSurveyHash(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let hash = '';
  for (let i = 0; i < length; i++) {
    hash += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return hash;
}

const getUniqueTitle = async (title) => {
  try {
    // Get user's surveys
    const authToken = localStorage.getItem('authToken');
    const response = await axios.get(serverUrl + '/survey/created', {
      headers: { Authorization: `Bearer ${authToken}` }
    });
    console.log('Existing surveys:', response.data);
    const existingTitles = response.data.map(survey => survey.title);
    
    // Remove any existing numbering pattern like "(1)" from the title
    const baseTitle = title.replace(/\s+\(\d+\)$/, '');
    
    // If original title doesn't exist, use it
    if (!existingTitles.includes(title)) {
      return title;
    }
    
    // Find next available number
    let counter = 1;
    let newTitle = `${baseTitle} (${counter})`;
    
    while (existingTitles.includes(newTitle)) {
      counter++;
      newTitle = `${baseTitle} (${counter})`;
    }
    
    return newTitle;
  } catch (error) {
    console.error('Error checking survey titles:', error);
    return title; // Return original if there's an error
  }
};

// Modified QuestionList component to include custom emotion functionality
const QuestionList = ({ questions, setQuestions }) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);
  const [newEmotion, setNewEmotion] = useState('');
  const [showEmotionInput, setShowEmotionInput] = useState(false);

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;
    setQuestions(updatedQuestions);
  };

  const handleTypeChange = (index, e) => {
    const newType = e.target.value;
    handleQuestionChange(index, 'type', newType);
  };

  const handleRemoveQuestion = (index) => {
    if (questions.length > 1) {
      const updatedQuestions = [...questions];
      updatedQuestions.splice(index, 1);
      setQuestions(updatedQuestions);
    }
  };

  const toggleEmotionInput = (index) => {
    setActiveQuestionIndex(index);
    setShowEmotionInput(!showEmotionInput);
    setNewEmotion('');
  };

  const addCustomEmotion = () => {
    if (newEmotion.trim() === '' || activeQuestionIndex === null) return;
    
    const updatedQuestions = [...questions];
    
    // Check if adding this emotion would exceed the limit of 16
    const uniqueEmotionsCount = new Set([
      ...updatedQuestions[activeQuestionIndex].emotions,
      newEmotion
    ]).size;
    
    if (uniqueEmotionsCount > 16) {
      alert('Maximum of 16 emotions allowed per question.');
      return;
    }
    
    // Add the new emotion to the emotions array for the specific question
    if (!updatedQuestions[activeQuestionIndex].emotions.includes(newEmotion)) {
      updatedQuestions[activeQuestionIndex].emotions = [
        ...updatedQuestions[activeQuestionIndex].emotions, 
        newEmotion
      ];
      
      // Add the new emotion to all other questions too
      updatedQuestions.forEach((question, idx) => {
        if (idx !== activeQuestionIndex && !question.emotions.includes(newEmotion)) {
          question.emotions.push(newEmotion);
        }
      });
      
      setQuestions(updatedQuestions);
    }
    
    // Reset the input field
    setNewEmotion('');
    setShowEmotionInput(false);
  };
  
  // 3. Also modify the toggleEmotion function to prevent adding emotions beyond the limit:
  const toggleEmotion = (questionIndex, emotion) => {
    const updatedQuestions = [...questions];
    const currentEmotions = updatedQuestions[questionIndex].emotions;
    const emotionIndex = currentEmotions.indexOf(emotion);
    
    if (emotionIndex === -1) {
      // Check if adding would exceed the limit
      if (currentEmotions.length >= 16) {
        alert('Maximum of 16 emotions allowed per question.');
        return;
      }
      
      // Add the emotion if it's not already selected
      updatedQuestions[questionIndex].emotions = [...currentEmotions, emotion];
    } else {
      // Remove the emotion if it's already selected
      updatedQuestions[questionIndex].emotions = currentEmotions.filter(e => e !== emotion);
    }
    
    setQuestions(updatedQuestions);
  };

  return (
    <div>
      {questions.map((question, index) => (
        <div key={index} className="question-container">
          <div className="question-header">
            <select 
              value={question.type} 
              onChange={(e) => handleTypeChange(index, e)}
              className="question-type-select"
            >
              <option value="text">Text</option>
              <option value="image">Image</option>
              <option value="video">Video</option>
            </select>
            <button 
              onClick={() => handleRemoveQuestion(index)} 
              className="remove-question-button"
            >
              ×
            </button>
          </div>
          
          {question.type === 'text' ? (
            <textarea
              placeholder="Enter text"
              value={question.answer}
              onChange={(e) => handleQuestionChange(index, 'answer', e.target.value.replace(/\s{2,}/g, ' '))}
              className="question-input"
            ></textarea>
          ) : (
            <input
              type="text"
              placeholder={`Enter ${question.type} URL`}
              value={question.answer}
              onChange={(e) => handleQuestionChange(index, 'answer', e.target.value.replace(/\s{2,}/g, ' ').trim())}
              className="question-input"
            />
          )}
          
          <div className="emotions-section">
            <p>Choose emotions:</p>
            <div className="emotions-container">
              {defaultEmotions.map((emotion, emoIndex) => (
                <button 
                  key={emoIndex} 
                  className={`emotion-button ${question.emotions.includes(emotion) ? 'selected' : ''}`}
                  onClick={() => toggleEmotion(index, emotion)}
                >
                  {emotion}
                </button>
              ))}
              
              {question.emotions
                .filter(emotion => !defaultEmotions.includes(emotion))
                .map((emotion, emoIndex) => (
                  <button 
                    key={`custom-${emoIndex}`} 
                    className="emotion-button selected custom"
                    onClick={() => toggleEmotion(index, emotion)}
                  >
                    {emotion}
                  </button>
                ))
              }
              
              {/* Add emotion button */}
              <button 
                className="add-emotion-button" 
                onClick={() => toggleEmotionInput(index)}
              >
                + Add Emotion
              </button>
            </div>
            
            {/* Input field for custom emotion (conditionally rendered) */}
            {showEmotionInput && activeQuestionIndex === index && (
              <div className="custom-emotion-input">
                <input
                  type="text"
                  placeholder="Enter custom emotion"
                  value={newEmotion}
                  maxLength={16}
                  onChange={(e) => setNewEmotion(e.target.value.replace(/\s{2,}/g, ' ').trim())}
                />
                <button 
                  onClick={addCustomEmotion}
                  className="add-button"
                >
                  Add
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export function SurveyCreationPage() {
  let navigate = useNavigate();

  const [questions, setQuestions] = useState([{ type: "text", answer: "", emotions: defaultEmotions }]); // Initialize with one empty question
  const [quizTitle, setQuizTitle] = useState('');
  const [quizDescription, setQuizDescription] = useState('');

  // Function to collect all unique emotions from existing questions
  const getAllUniqueEmotions = () => {
    const allEmotions = new Set();
    
    // Add all emotions from all questions to the set
    questions.forEach(question => {
      question.emotions.forEach(emotion => {
        allEmotions.add(emotion);
      });
    });
    
    return [...allEmotions];
  };

  const validateForm = () => {
    // Check if title is empty or too short
    if (quizTitle.trim() === "") {
      return { isValid: false, message: "Please enter a poll title." };
    } else if (quizTitle.trim().length <= 3) {
      return { isValid: false, message: "Poll title must be more than 3 letters long." };
    }
    
    // Check if description is empty or too short
    if (quizDescription.trim() === "") {
      return { isValid: false, message: "Please enter a poll description." };
    } else if (quizDescription.trim().length <= 3) {
      return { isValid: false, message: "Poll description must be more than 3 letters long." };
    }
    
    // Validate each question
    for (let question of questions) {
      if (question.type === 'text' && question.answer.trim() === "") {
        return { isValid: false, message: "Please fill in all text questions before submitting." };
      } else if (question.type === 'text' && question.answer.trim().length <= 3) {
        return { isValid: false, message: "Text questions must be more than 3 letters long." };
      } else if ((question.type === 'image' || question.type === 'video') && !question.answer.startsWith('http')) {
        return { isValid: false, message: `Please enter a valid ${question.type} URL starting with http or https.` };
      } else if (question.emotions.length < 5) {
        return { isValid: false, message: "Please select at least 5 emotions for each question." };
      }
    }
    
    return { isValid: true, message: "" };
  };

  const handleSubmit = async () => {
    const validation = validateForm();
    if (validation.isValid) {
      // Generate survey hash
      const hash = generateSurveyHash(8);
  
      // Retrieve authToken from localStorage
      const authToken = localStorage.getItem('authToken');
      const uniqueTitle = await getUniqueTitle(quizTitle);
      console.log('Unique title:', uniqueTitle);
      // If authToken exists, make the request to the server
      if (authToken) {
        try {
          // Make a POST request to your server endpoint using async/await
          const response = await axios.post(serverUrl + '/survey/new', {
            questions: questions,
            hash: hash,
            title: uniqueTitle,
            description: quizDescription,
          }, {
            headers: {
              Authorization: `Bearer ${authToken}`, // Include bearer token in the headers
            },
          });
          // Handle response from server
          console.log('Survey submitted successfully:', response.data);
          navigate('/create-survey/success', { state: { hash: hash } });
        } catch (error) {
          // Handle errors
          console.error('Error submitting survey:', error);
        }
      } else {
        console.error('Auth token not found in localStorage');
        navigate('/')
        
      }
    } else {
      console.error('Form validation failed');
      alert(validation.message);
    }
  };

  // Modified function to handle adding new questions with all existing emotions
  const handleAddQuestion = () => {
    // Check if the maximum number of questions has been reached
    if (questions.length >= 10) {
      alert("Maximum of 10 questions allowed per poll.");
      return;
    }
    
    const allEmotions = getAllUniqueEmotions();
    setQuestions([...questions, { answer: "", type: "text", emotions: allEmotions }]);
  };

  return (
    <div className='base-unfixed-footer'>
      <div className='base-unfixed-footer-content'>
        <div className='CreateSyrveyPage'>
          <div className="survey-creation-page">
            {/* Input fields for quiz title and description */}
            <div className='syrvey-top-container'> 
              <div className='syrvey-top'>
                <div className='syrvey-top-text'><span>Poll title:</span></div>
                <input
                  type="text"
                  placeholder="Enter poll title"
                  value={quizTitle}
                  onChange={(e) => setQuizTitle(e.target.value.replace(/\s{3,}/g, ' '))}
                />

                <div className='syrvey-top-text'><span>Poll description:</span></div>
                <textarea
                  placeholder="Enter poll description"
                  value={quizDescription}
                  onChange={(e) => setQuizDescription(e.target.value.replace(/\s{3,}/g, ' '))}
                ></textarea>
              </div>
            </div>
        
            {/* Questions section */}
            <div className='syrvey-questions-container'>
              <div className='syrvey-questions'>
                <QuestionList
                  questions={questions}
                  setQuestions={setQuestions}
                />
              </div>
            </div>
        
            {/* Button for adding questions */}
            <div className="syrvey-add-submit-container">
              <button onClick={handleAddQuestion} className="add-question-button">
                + ADD QUESTION
              </button>
          
              <button onClick={handleSubmit} className="submit-survey-button">
                Submit Poll
              </button>
            </div>
          </div>
        </div>
      </div>

      <TopPanelBase/>
      <BotPanel is_fixed={false}/>
    </div>
  );
}