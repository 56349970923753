import React from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import '../takeSurvey/surveyPage.css';
import './resultsSurvey.css';


const calculateEmotionLevels = (responses, emotions) => {
  const emotionCounts = emotions.reduce((acc, emotion) => {
    acc[emotion] = 0;
    return acc;
  }, {});

  Object.values(responses).flat().forEach(response => {
    emotions.forEach(emotion => {
      if (response.value) {
        emotionCounts[emotion] += response.value;
      }
    });
  });

  const totalResponses = Object.values(responses).flat().length;
  const emotionPercentages = {};

  for (let emotion of emotions) {
    emotionPercentages[emotion] = totalResponses ? (emotionCounts[emotion] / totalResponses) : 0;
  }

  return emotionPercentages;
};

const renderContent = (type, content) => {
  switch (type) {
    case 'text':
      return <p>{content}</p>;
    case 'image':
      return <img src={content} alt="question-related" className="question-image" />;
    case 'video':
      // Assuming content is a YouTube URL, extract the video ID
      const videoId = new URL(content).searchParams.get('v');
      const embedUrl = `https://www.youtube.com/embed/${videoId}`;
      return (
        <iframe
          title="video-question"
          width="560"
          height="315"
          src={embedUrl}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    default:
      return null;
  }
};

const Question = ({ question }) => {
  const emotionPercentages = calculateEmotionLevels(question.responses, question.emotions);
  
  return (
    <div className='result-question-unit'>
      {renderContent(question.questionType, question.questionText)}
    </div>
  );
};

export default Question;
