import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './surveyPage.css';
import { serverUrl } from '../Utils/storage';
import axios from 'axios';
import Question from './Question';
import TopPanelBase from '../Components/TopPanel/TopPanelBase'
import BotPanel from '../Components/BottomPanel/BotPanel'

function SurveyPage() {
  const { surveyHash } = useParams();
  const [survey, setSurvey] = useState(null);
  const [emotions, setEmotions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); 
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      // Check if user is logged in
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        // User is not logged in, show alert and then redirect
        setIsLoading(false); // Stop loading to show alert
        alert('Please login to access the survey');
        
        // Add delay before redirect
        setTimeout(() => {
          navigate('/login');
        }, 1000); // 1 second delay
        return;
      }
      
      // Token exists in storage, assume it's valid
      setIsAuthenticated(true);
      
      try {
        const response = await axios.get(`${serverUrl}/survey/take/${surveyHash}`, {
          headers: { Authorization: `Bearer ${authToken}` }
        });
        
        if (response.status !== 200) {
          throw new Error('Survey not found');
        }
        
        const data = response.data;
        setSurvey(data);
  
        const initialEmotions = {};
        data.questions.forEach(question => {
          const questionEmotions = {};
          question.emotions.forEach(emotion => {
            questionEmotions[emotion] = 0; 
          });
          initialEmotions[question.question_id] = questionEmotions;
        });
        setEmotions(initialEmotions);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          // Auth error
          localStorage.removeItem('authToken');
          alert('Session expired. Please login again');
          
          setTimeout(() => {
            navigate('/login');
          }, 1000);
        } else {
          // Other error
          setError(err.message);
        }
      } finally {
        setIsLoading(false);
      }
    };
    
    checkAuth();
  }, [surveyHash]); // Remove navigate from dependencies

  const fetchSurvey = async (authToken) => {
    try {
      const response = await axios.get(`${serverUrl}/survey/take/${surveyHash}`, {
        headers: { Authorization: `Bearer ${authToken}` }
      });
      
      if (response.status !== 200) {
        throw new Error('Survey not found');
      }
      
      const data = response.data;
      setSurvey(data);

      const initialEmotions = {};
      data.questions.forEach(question => {
        const questionEmotions = {};
        question.emotions.forEach(emotion => {
          questionEmotions[emotion] = 0; 
        });
        initialEmotions[question.question_id] = questionEmotions;
      });
      setEmotions(initialEmotions);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  function handleEmotionChange(questionId, emotion, value) {
    setEmotions(prev => ({
      ...prev,
      [questionId]: {
        ...prev[questionId],
        [emotion]: value
      }
    }));
  }

  function validateResponses() {
    // Clear any existing error message timers
    if (window.errorMessageTimer) {
      clearTimeout(window.errorMessageTimer);
    }
    
    let totalNonZeroResponses = 0;
    
    for (const questionId in emotions) {
      const emotionValues = Object.values(emotions[questionId]);
      const sum = emotionValues.reduce((acc, val) => acc + val, 0);
      
      // Count how many emotion ratings are non-zero
      const nonZeroCount = emotionValues.filter(val => val > 0).length;
      totalNonZeroResponses += nonZeroCount;
      
      // If all responses for a question sum to 0, show error and return false
      if (sum === 0) {
        showTemporaryErrorMessage("Each question must have at least 3 non-zero response");
        return false;
      }
      
      // Minimum response validation for each question
      if (nonZeroCount < 3) {
        showTemporaryErrorMessage("Please rate at least 3 emotions for each question");
        return false;
      }
    }
    
    // Clear error message if validation passes
    setErrorMessage("");
    return true;
  }

  function showTemporaryErrorMessage(message) {
    setErrorMessage(message);
    
    window.errorMessageTimer = setTimeout(() => {
      setErrorMessage("");
    }, 2000);
  }
  
  async function submitSurvey() {
    // Check validation before submission
    if (!validateResponses()) {
      return;
    }
  
    setIsSubmitting(true);
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        alert('Please login to submit the survey');
        
        setTimeout(() => {
          navigate('/login');
        }, 1000);
        return;
      }
      
      const response = await axios.post(
        `${serverUrl}/survey/submit`,
        { surveyHash, emotions },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
  
      if (response.data && response.data.success) {
        setSubmitted(true);
        navigate(`/survey-results/${survey.survey_id}`);
      }
    } catch (error) {
      console.error('Error submitting survey:', error);
      // If error is due to auth issues
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('authToken');
        alert('Session expired. Please login again');
        
        setTimeout(() => {
          navigate('/login');
        }, 1000);
      }
    } finally {
      setIsSubmitting(false);
    }
  }
  

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!survey) return <div>No survey data available.</div>;
  if (!isAuthenticated) return <div>Redirecting to login...</div>;

  return (
    <div className='base-unfixed-footer'>
      <div className='base-unfixed-footer-content'>
        <div className='survey-page-main'>
          <div className='survey-page-top'>
            <h1>{survey.title}</h1>
            <p>{survey.description}</p>
          </div>

          <div className='survey-page-question-container'>
            {survey.questions.map((question) => (
              <Question
                key={question.question_id}
                question={question}
                emotions={emotions[question.question_id] || {}}
                handleEmotionChange={handleEmotionChange}
              />
            ))}
          </div>

          {errorMessage && <div className="error-message">{errorMessage}</div>}
          
          {!submitted && (
            <button onClick={submitSurvey} disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit Survey'}
            </button>
          )}
          {submitted && <div>Survey submitted successfully!</div>}
        </div>
      </div>
      <TopPanelBase />
      <BotPanel is_fixed={false} />
    </div>
  );
}

export default SurveyPage;