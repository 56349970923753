import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { serverUrl } from "../Utils/storage";
import "./AdminMenuButton.css";

// List of email addresses that have admin privileges
const ADMIN_EMAILS = [
  "kolodnyi@vntu.edu.ua",
  "youremail@gmail.com",
  "youremail1@gmail.com",

  // Add any other admin emails here
];

const ADMIN_ICON_URL =
  "https://cdn-icons-png.flaticon.com/512/2099/2099058.png";

function AdminMenuButton() {
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is admin
    const checkAdminStatus = async () => {
      try {
        const response = await fetch(serverUrl + "/user/profile", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();

          // Check if user's email is in the admin list
          if (data.email && ADMIN_EMAILS.includes(data.email.toLowerCase())) {
            console.log("is admin in Admin");
            setIsAdmin(true);
          } else {
            // Redirect non-admin users away from this page
            alert("You don't have permission to access this page");
            navigate("/logged-in");
          }
        }
      } catch (error) {
        console.error("Error checking admin status:", error);
      }
    };

    checkAdminStatus();
  }, []);

  if (!isAdmin) {
    // Don't render anything if user is not an admin
    return null;
  }

  return (
    <button className="admin-menu-button" onClick={() => navigate("/admin")}>
      Admin Panel
    </button>
  );
}

export default AdminMenuButton;
