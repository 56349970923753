import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { serverUrl } from '../Utils/storage';
import './EditSurvey.css'; // Імпорт файлу стилів
import TopPanelBase from '../Components/TopPanel/TopPanelBase'
import BotPanel from '../Components/BottomPanel/BotPanel'

function EditSurvey() {
    const { id } = useParams(); // Отримуємо id опитування з URL
    const [survey, setSurvey] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [questions, setQuestions] = useState([]);
    const [message, setMessage] = useState(''); // Стан для повідомлення
    const [questionMessage, setQuestionMessage] = useState(''); // Стан для повідомлення про зміни питань
    const navigate = useNavigate(); // Використовуємо useNavigate для навігації

    const authToken = localStorage.getItem('authToken');

    useEffect(() => {
        // Завантажуємо дані опитування при завантаженні компонента
        const fetchSurvey = async () => {
            try {
                const response = await axios.get(`${serverUrl}/survey/${id}`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`, // Include bearer token in the headers
                    },
                });
                setSurvey(response.data);
                setTitle(response.data.title);
                setDescription(response.data.description);
                setQuestions(response.data.questions);
            } catch (error) {
                console.error('Error fetching survey:', error);
            }
        };

        fetchSurvey();
    }, [id, authToken]);

    const handleSave = async () => {
        try {
            const response = await axios.put(`${serverUrl}/survey/${id}`, {
                title,
                description
                
            }, {
                headers: {
                    Authorization: `Bearer ${authToken}`, // Include bearer token in the headers
                },
            });
            if (response.status === 200) { // Перевірка статусу відповіді
                setMessage('Changes saved'); // Встановлюємо повідомлення після успішного збереження
                setTimeout(() => navigate('/my-surveys'), 3000); // Переадресація через 3 секунди
            } else {
                setMessage('Failed to save changes'); // Повідомлення про помилку
            }
        } catch (error) {
            console.error('Error saving survey:', error);
            setMessage('Error saving changes'); // Повідомлення про помилку
        }
    };

    const handleQuestionChange = (index, key, value) => {
        const newQuestions = [...questions];
        newQuestions[index][key] = value;
        setQuestions(newQuestions);
    };

    const handleSaveQuestion = async (questionId, question) => {
        try {
            const response = await axios.put(`${serverUrl}/survey/${id}/questions/${questionId}`, question, {
                headers: {
                    Authorization: `Bearer ${authToken}`, // Include bearer token in the headers
                },
            });
            if (response.status === 200) { // Перевірка статусу відповіді
                setQuestionMessage('Question changes saved'); // Встановлюємо повідомлення після успішного збереження
            } else {
                setQuestionMessage('Failed to save question changes'); // Повідомлення про помилку
            }
        } catch (error) {
            console.error('Error saving question:', error);
            setQuestionMessage('Error saving question changes'); // Повідомлення про помилку
        }
    };

    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => setMessage(''), 3000); // Прибираємо повідомлення через 3 секунди
            return () => clearTimeout(timer); // Очищаємо таймер при розмонтуванні компонента
        }
    }, [message]);

    useEffect(() => {
        if (questionMessage) {
            const timer = setTimeout(() => setQuestionMessage(''), 3000); // Прибираємо повідомлення через 3 секунди
            return () => clearTimeout(timer); // Очищаємо таймер при розмонтуванні компонента
        }
    }, [questionMessage]);

    if (!survey) {
        return <div>Loading...</div>;
    }

    return (
        <div className='base-unfixed-footer'>
        <div className='base-unfixed-footer-content'>
        <div className="edit-survey-page">

            <TopPanelBase/>

            <div className="edit-survey-container">
                <div className='edit-survey-top'>
                <h2>Edit EmPoll</h2>
                <div>
                    <label>Title:</label>
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
                <div>
                    <label>Description:</label>
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                </div>
                <button onClick={() => navigate('/my-surveys')}>Go Back</button> {/* Додана кнопка "Go Back" */}
                <button onClick={handleSave}>Save</button>

                </div>

                {message && <p className="message">{message}</p>}

                <h2>Questions</h2>
                {questions.map((question, index) => (
                    <div key={question.question_id} className="question-item">
                        <div>
                            <label>Type:</label>
                            <select
                                value={question.type}
                                onChange={(e) => handleQuestionChange(index, 'type', e.target.value)}
                            >
                                <option value="text">Text</option>
                                <option value="image">Image</option>
                                <option value="video">Video</option>
                            </select>
                        </div>
                        <div>
                            <label>Question:</label>
                            <input
                                type="text"
                                value={question.question}
                                onChange={(e) => handleQuestionChange(index, 'question', e.target.value)}
                            />
                        </div>
                        {/* <div>
                            <label>Emotions:</label>
                            <input
                                type="text"
                                value={question.emotions.join(', ')}
                                onChange={(e) => handleQuestionChange(index, 'emotions', e.target.value.split(', '))}
                            />
                        </div> */}
                        <button onClick={() => handleSaveQuestion(question.question_id, question)}>Save Question</button>
                    </div>
                ))}

                {questionMessage && <p className="question-message">{questionMessage}</p>} {/* Повідомлення про зміни питань */}
            </div>
        </div>
        </div>
        <BotPanel is_fixed={false}/>
        </div>
    );
}

export default EditSurvey;
