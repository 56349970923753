import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { serverUrl } from "../Utils/storage";
import Question from "./Question";
import QuestionLeft from "./QuestionLeft";
import QuestionRight from "./QuestionRight";
import ChartComponent from "./ChartComponent";
import { generateColors } from "./ChartComponent";

import "./resultsSurveLocalAdmin.css";
import TopPanelBase from "../Components/TopPanel/TopPanelBase";
import BotPanel from "../Components/BottomPanel/BotPanel";
import SoulMate from "./SoulMate";
import QuestionLeftSoulMate from "./QuestionLefSoulMate";

const SurveyResultsAdmin = () => {
  const { id } = useParams(); // Отримуємо id опитування з URL
  const { user_id } = useParams(); // Отримуємо id опитування з URL
  const [data, setData] = useState(null);
  const [similar, setSimilar] = useState(null);
  const [soulmate, setSoul] = useState(null);
  const [spearmen, setSp] = useState(null);

  const [respCount, setRespCounter] = useState(null);

  useEffect(() => {
    // Отримуємо дані результатів при завантаженні компонента
    const fetchResults = async () => {
      try {
        const response = await axios.get(
          `${serverUrl}/survey/${id}/results/${user_id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Додаємо токен авторизації у заголовки
            },
          }
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching survey results:", error);
      }
    };

    const SoulMate = async () => {
      try {
        const response = await axios.get(
          `${serverUrl}/survey/soulmate/${id}/${user_id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Додаємо токен авторизації у заголовки
            },
          }
        );
        setSoul(response.data);
      } catch (error) {
        console.error("Error fetching survey results:", error);
      }
    };

    const Spearmen = async () => {
      try {
        const response = await axios.get(
          `${serverUrl}/survey/spearmen/${id}/${user_id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Додаємо токен авторизації у заголовки
            },
          }
        );
        setSp(response.data);
      } catch (error) {
        console.error("Error fetching survey results:", error);
      }
    };

    const RespCount = async () => {
      try {
        const response = await axios.get(
          `${serverUrl}/survey/resp-count/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Додаємо токен авторизації у заголовки
            },
          }
        );
        setRespCounter(response.data);
      } catch (error) {
        console.error("Error fetching survey resp count :", error);
      }
    };

    const fetchSimilar = async () => {
      try {
        const response = await axios.get(
          `${serverUrl}/survey/${id}/all-without-del/${user_id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Додаємо токен авторизації у заголовки
            },
          }
        );
        setSimilar(response.data);
      } catch (error) {
        console.error("Error fetching survey results:", error);
      }
    };

    fetchSimilar();
    SoulMate();
    Spearmen();
    fetchResults();
    RespCount();

    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href =
      "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css";
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, [id]);

  if (!data || !similar || !soulmate || !spearmen) {
    return <div>Loading results...</div>;
  }

  function getCorrelationMessage(spResult) {
    if (spResult > 0.8) {
      return "Hello, Soulmate! ";
    } else if (spResult > 0.5) {
      return "Candidate for a Soulmate ";
    } else if (spResult > 0.2) {
      return "";
    }
    return "Too few responses. "; // or any default text if needed
  }
  const findThreeMaxValuesAndIndices = (responses) => {
    const values = responses.map((resp) => resp.value);

    // Create an array of value-index pairs
    const valueIndexPairs = values.map((value, index) => ({ value, index }));

    // Sort by value descending
    const sortedPairs = valueIndexPairs.sort((a, b) => b.value - a.value);

    // Take the top 3 values and their indices
    const topTwo = sortedPairs.slice(0, 2);

    const maxValues = topTwo.map((pair) => pair.value);
    const maxIndices = topTwo.map((pair) => pair.index);

    return { maxValues, maxIndices };
  };
  const areIndicesEqualUnordered = (indices1, indices2) => {
    // Check if both arrays have the same length
    if (indices1.length !== indices2.length) {
      return false;
    }

    // Create sets from both arrays and compare their contents
    const set1 = new Set(indices1);
    const set2 = new Set(indices2);

    // Compare the two sets
    return [...set1].every((index) => set2.has(index));
  };

  return (
    <div style={{ width: "100%" }}>
      <Container className="result-admin-survey-main">
        <div className="result-admin-survey-top">
          <h1>Poll Results</h1>
          <h2 style={{ fontSize: "30px" }}>{data.survey.title}</h2>
          <p>{data.survey.description}</p>
        </div>

        <h3>Questions</h3>
        <div className="result-admin-survey-ser">
          {data.questions.map((question, index) => {
            /*const responsesLeft = question.responses;*/
            let responsesLeft = [];
            question.responses.forEach((resp) => {
              responsesLeft.push(resp.value);
            });
            const responsesRight = similar["avgValue"][question.questionId];

            const colors = generateColors(question.emotions);
            const spResult = spearmen.spearmanCorrelation;
            const message = getCorrelationMessage(spResult);

            return (
              <div key={question.questionId} className="mb-4">
                <div
                  key={question.questionId}
                  className="survey-result-container"
                >
                  <Question question={question} />

                  <div className="affinity-banner">
                    <h3>
                      <span
                        className={`affinity-label ${
                          spResult > 0.8 ? "high-affinity" : ""
                        }`}
                      >
                        {message}
                      </span>
                      {spResult >= 0.2 && (
                        <span className="affinity-value">
                          Emotional affinity:{" "}
                          <span
                            className={spResult > 0.8 ? "high-affinity" : ""}
                          >
                            {Math.round(spResult * 100)}%
                          </span>
                        </span>
                      )}
                      <span className="affinity-share">
                        Please share this survey!
                      </span>
                    </h3>
                  </div>

                  <div className="questions-comparison">
                    <div className="question-box left-question">
                      <QuestionLeft question={question} />
                    </div>
                    <div className="question-box right-question">
                      <QuestionLeft
                        question={spearmen.surveyResults.questions[index]}
                      />
                    </div>
                  </div>
                </div>

                <br></br>
                <h2>Difference between your and collective response</h2>
                <Row>
                  <Col xs={6}>
                    <QuestionLeft question={question} />
                  </Col>
                  <Col xs={6}>
                    <QuestionLeftSoulMate
                      question={question}
                      questionSoulMate={responsesRight}
                    />
                  </Col>
                </Row>
                <br></br>
                <h2>Your and Collective response</h2>
                <Row>
                  <Col xs={6}>
                    <ChartComponent
                      responses={responsesLeft}
                      emotions={question.emotions}
                      colors={colors}
                    />
                  </Col>

                  <Col xs={6}>
                    <ChartComponent
                      responses={responsesRight}
                      emotions={question.emotions}
                      colors={colors}
                    />
                  </Col>
                </Row>
                <br></br>
              </div>
            );
          })}
        </div>
      </Container>

      <TopPanelBase />
      <BotPanel is_fixed={false} />
    </div>
  );
};
export default SurveyResultsAdmin;

{
  /* You and Your Soulmate
            <Row>
              <Col xs={6}>
                <QuestionLeft question={question} />
            </Col>
            
            <Col xs={6}>
                <QuestionLeft question={soulmate.questions[index]} />
            </Col>
            </Row> */
}

{
  /* <Row>
            <Col xs={6}>
                <QuestionLeft question={question} />
            </Col>
              <Col xs={6}>
                <QuestionLeftSoulMate question={question} questionSoulMate={soulmate.questions[index]} />
            </Col>
            </Row>*/
}
