import React, { useState } from 'react';
import './BotPanel.css'


const BotPanel = ({is_fixed}) =>
{
    return(
        <footer className={is_fixed ? "footer_fixed": "footer"} >
            © CollEmPoll, V. Kolodnyi, V. Haruk, 2025
        </footer>
    );
};


export default BotPanel;