import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import LoginPage from "./Auth/loginPage";
import LoggedInPage from "./Auth/loggedInPage";
import { SurveyCreationPage } from "./createSurvey/createSyrvey";
import StoreToken from "./Auth/StoreToken";
import TakeSurveyPage from "./takeSurvey/takeSurvey";
import SurveyPage from "./takeSurvey/surveyPage";
import HashPage from "./createSurvey/HashPage";
import MySurvey from "./mySurvey/mySurvey";
import EditSurvey from "./mySurvey/editSurvey";
import SurveyResults from "./mySurvey/resultsSurvey";
import ForgotPasswordPage from "./Auth/ForgotPasswordPage";
import SurveyPassedUsersAdmin from "./mySurvey/SurveyPassedUsersAdmin";
import SurveyResultsAdmin from "./mySurvey/resultsSurveLocalAdmin";
import AdminKolodniy from "./mySurvey/AdminKolodniy";
import HelpPage from "./Auth/HelpPage";
import AdminPanel from "./Admin/AdminPanel";
import UserSurveysAdmin from "./Admin/UserSurveysAdmin";
import AdminSurveyResults from "./Admin/UserResultsForAdminPanel";
import SurveyRespondentsAdmin from "./Admin/SurveyRespondentsAdmin";
import AdminSurveyResultsLocal from "./Admin/AdminSurveyResults";
import AboutPage from "./Pages/AboutPage";

// Admin route protection component
const AdminRoute = ({ children }) => {
  const authToken = localStorage.getItem("authToken");

  // In a real application, you would also verify if the user is an admin
  // This is a simplified version that just checks for authentication
  if (!authToken) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

function App() {
  // Check if the authentication token exists in local storage
  const authToken = localStorage.getItem("authToken");

  return (
    <Router>
      <script src="http://localhost:8097"></script>
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route
              path="/"
              element={
                authToken ? (
                  <Navigate replace to="/logged-in" />
                ) : (
                  <Navigate replace to="/login" />
                )
              }
            />
            <Route path="/logged-in" element={<LoggedInPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/survey/:surveyHash" element={<SurveyPage />} />
            <Route path="/storeToken" element={<StoreToken />} />
            <Route path="/take-survey" element={<TakeSurveyPage />} />
            <Route path="/create-survey" element={<SurveyCreationPage />} />
            <Route path="/create-survey/success" element={<HashPage />} />
            <Route path="/my-surveys" element={<MySurvey />} />
            <Route path="/edit-survey/:id" element={<EditSurvey />} />
            <Route path="/survey-results/:id" element={<SurveyResults />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route
              path="/survey-results-admin/:survey_id"
              element={
                <AdminRoute>
                  <SurveyPassedUsersAdmin />
                </AdminRoute>
              }
            />
            <Route
              path="/results-admin/:id/:user_id"
              element={<SurveyResultsAdmin />}
            />
            <Route
              path="/results-kolodniy/:id/:user_id"
              element={<AdminKolodniy />}
            />
            <Route path="/help" element={<HelpPage />} />
            <Route path="/about" element={<AboutPage />} />

            {/* Admin Routes */}
            <Route
              path="/admin"
              element={
                <AdminRoute>
                  <AdminPanel />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/user-surveys/:userId"
              element={
                <AdminRoute>
                  <UserSurveysAdmin />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/survey-results/:id"
              element={
                <AdminRoute>
                  <SurveyRespondentsAdmin />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/user-results/:id/:user_id"
              element={
                <AdminRoute>
                  <AdminSurveyResults />
                </AdminRoute>
              }
            />
            <Route
              path="/survey-results-admin/:id"
              element={<AdminSurveyResults />}
            />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
