import React, { useState, useEffect } from 'react';
import { serverUrl } from '../Utils/storage';
import './LoggedInPage.css'; // Підключення CSS файлу
import { useNavigate } from 'react-router-dom';
import TopPanelBase from '../Components/TopPanel/TopPanelBase';
import BotPanel from '../Components/BottomPanel/BotPanel';
import AdminMenuButton from '../Admin/AdminMenuButton'; // Import the AdminMenuButton component

function LoggedInPage() {
  const [userInfo, setUserInfo] = useState(null);
  let navigate = useNavigate();
  
  useEffect(() => {
    async function fetchUserProfile() {
      try {
        const response = await fetch(serverUrl + '/user/profile', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          console.log('uerInfo:', data)
          setUserInfo(data);
        } else {
          console.error('Failed to fetch user profile:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching user profile:', error.message);
      }
    }
    fetchUserProfile();
  }, []);
  
  // Припустимо, тут будуть функції для взаємодії з опитуваннями (ще не реалізовані)
  const createSurvey = () => {
    console.log("Create a new survey");
    navigate('/create-survey');
  };
  
  const takeSurvey = () => {
    console.log("Take a survey");
    navigate('/take-survey');
  };
  
  const mySurveys = () => {
    console.log("View my surveys");
    navigate('/my-surveys');
  };
  
  console.log(userInfo);
  
  const logout = async () => {
    localStorage.removeItem('authToken');
    window.location.href = '/';
  };
  
  return (
    <div className="LoggedInPage">
      <TopPanelBase/>
      {userInfo ? (
        <div>
          <p>Welcome, {userInfo.name}!</p>
          {userInfo.picture && userInfo.picture.startsWith('http') ? (
            <img src={userInfo.picture} alt="Profile" />
          ) : (
            // If picture URL doesn't contain http, render a default image
            <img src="https://t3.ftcdn.net/jpg/05/87/76/66/360_F_587766653_PkBNyGx7mQh9l1XXPtCAq1lBgOsLl6xH.jpg" alt="Profile" />
          )}
          
          {/* Admin Menu Button - will only display for admin users */}
          <AdminMenuButton />
          
          <div>
            <button onClick={logout} className="logout-button">Logout</button>
          </div>
          <div>
            <button type="button" onClick={createSurvey}>Створити опитування</button>
            <button type="button" onClick={takeSurvey}>Пройти опитування</button>
            <button type="button" onClick={mySurveys}>Мої опитування</button>
          </div>
        </div>
      ) : (
        <div>
          <p>No user information available. Please Log In!</p>
          <div>
            <button onClick={logout} className="logout-button">Logout</button>
          </div>
          <div>
            <button type="button" onClick={logout}>Login</button>
          </div>
        </div>
      )}
      <BotPanel is_fixed={true}/>
    </div>
  );
}

export default LoggedInPage;