import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Table, Button, Card, ProgressBar } from "react-bootstrap";
import axios from "axios";
import { serverUrl } from "../Utils/storage";
import TopPanelBase from "../Components/TopPanel/TopPanelBase";
import BotPanel from "../Components/BottomPanel/BotPanel";
import "./AdminStyle.css";

const SurveyRespondentsAdmin = () => {
  // Get id from either parameter name (:id or :survey_id)
  const params = useParams();
  const id = params.id || params.survey_id;

  const navigate = useNavigate();
  const [surveyInfo, setSurveyInfo] = useState(null);
  const [respondents, setRespondents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get survey information
        const surveyResponse = await axios.get(
          `${serverUrl}/admin/survey/${id}/results`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );
        setSurveyInfo(surveyResponse.data);

        // Get list of respondents using the new endpoint
        const respondentsResponse = await axios.get(
          `${serverUrl}/admin/survey-passed/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );

        // Extract the users array from the response
        if (respondentsResponse.data && respondentsResponse.data.success) {
          setRespondents(respondentsResponse.data.users || []);
        } else {
          throw new Error(
            respondentsResponse.data?.message ||
              "Failed to load respondents data"
          );
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching survey data:", error);
        setError(
          "Failed to load survey respondents. Please check your permissions or try again."
        );
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleViewResults = (userId, sessionId) => {
    // Navigate to the detailed results page with session ID if available
    navigate(`/admin/user-results/${id}/${userId}`);
  };

  const handleDeleteResponse = async (userId, sessionId) => {
    if (!window.confirm("Are you sure you want to delete this response?")) {
      return;
    }

    try {
      // Include session_id in the delete request if available
      await axios.get(
        `${serverUrl}/admin/delete-survey-response/${userId}${
          sessionId ? `/${sessionId}` : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      // Update the list after deletion
      setRespondents(
        respondents.filter(
          (user) => !(user.user_id === userId && user.session_id === sessionId)
        )
      );
    } catch (error) {
      console.error("Error deleting response:", error);
      alert("Failed to delete response. Please try again.");
    }
  };

  if (loading) {
    return (
      <div className="admin-results-container">
        <TopPanelBase />
        <Container className="admin-content">
          <div className="text-center p-5">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p className="mt-3">Loading respondents data...</p>
          </div>
        </Container>
        <BotPanel is_fixed={false} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin-results-container">
        <TopPanelBase />
        <Container className="admin-content">
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
          <Button onClick={() => navigate("/admin")} variant="primary">
            Back to Admin Panel
          </Button>
        </Container>
        <BotPanel is_fixed={false} />
      </div>
    );
  }

  return (
    <div className="admin-results-container">
      <TopPanelBase />

      <Container className="admin-content">
        <div className="admin-header">
          <h1 className="page-main-heading">Survey Respondents</h1>
          <Button variant="primary" onClick={() => navigate("/admin")}>
            Back to Admin Panel
          </Button>
        </div>

        {surveyInfo && (
          <Card className="survey-info-card">
            <Card.Header className="survey-info-header">
              <h2>{surveyInfo.title}</h2>
            </Card.Header>
            <Card.Body className="survey-info-body">
              <p className="survey-description">{surveyInfo.description}</p>
              <div className="respondent-count">
                Total Respondents: {respondents.length}
              </div>
            </Card.Body>
          </Card>
        )}

        {respondents.length === 0 ? (
          <div className="empty-state">
            <i className="bi bi-inbox"></i>
            <p>No responses for this survey yet.</p>
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Table responsive hover className="respondent-table">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Email</th>
                    <th>Last Response</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {respondents.map((user, index) => (
                    <tr key={index}>
                      <td>
                        <div className="user-info">
                          {user.picture && (
                            <img
                              src={user.picture}
                              alt="User"
                              className="user-avatar"
                            />
                          )}
                          <span className="user-name">
                            {user.name || `Anonymous User ${user.user_id}`}
                          </span>
                        </div>
                      </td>
                      <td className="small-text-cell">{user.email || "N/A"}</td>
                      <td className="small-text-cell">
                        {user.last_response_time
                          ? new Date(user.last_response_time).toLocaleString()
                          : "N/A"}
                      </td>
                      <td>
                        <div className="action-buttons">
                          <Button
                            variant="primary"
                            size="sm"
                            className="view-btn"
                            onClick={() => handleViewResults(user.user_id)}
                          >
                            View Results
                          </Button>
                          <Button
                            variant="danger"
                            size="sm"
                            className="delete-btn"
                            onClick={() =>
                              handleDeleteResponse(
                                user.user_id,
                                user.session_id
                              )
                            }
                          >
                            Delete
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        )}
      </Container>

      <BotPanel is_fixed={false} />
    </div>
  );
};

export default SurveyRespondentsAdmin;
