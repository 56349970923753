import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { serverUrl } from '../Utils/storage';
import TopPanelBase from '../Components/TopPanel/TopPanelBase'
import { useNavigate } from 'react-router-dom';

import info_icon from '../icons/info_icon.png'
import './SurveyPassedUsersAdmin.css'
import BotPanel from '../Components/BottomPanel/BotPanel'


const SurveyPassedUsersAdmin = () => {
  const { survey_id } = useParams();
  const [responses, setResponses] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    // Replace this URL with your actual API endpoint
    axios.get(`${serverUrl}/survey/results-admin/${survey_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`, // Додаємо токен авторизації у заголовки
        },
      })
      .then(response => {
        setResponses(response.data);
        console.log('Survey results fetched successfully!', response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the survey results!', error);
      });
  }, [survey_id]);

  const deleteResponse = async (survey_id, res_id) => {
    const confirmed = window.confirm('Are you sure you want to delete this response?');
    if (!confirmed) return;
  
    try {
      const response = await axios.get(`${serverUrl}/survey/delete-response/${survey_id}/${res_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status === 200) {
        console.log('Response deleted:', response.data);
        alert('Response deleted successfully');
        // Optionally, refresh the survey list or update the state to remove the deleted response
      } else {
        console.error('Failed to delete response:', response.statusText);
        alert('Failed to delete response');
      }
    } catch (error) {
      console.error('Error deleting response:', error.message);
      alert('An error occurred while deleting the response');
    }
  }


  return (
    <div className='base-unfixed-footer'>
    <div className='base-unfixed-footer-content'>
    <div className='survey-users-base'>
      <TopPanelBase/>
      <div className='survey-users-top'>
        <h1>Poll results for users: {/*survey_id*/}</h1>
      </div>
      <div className='survey-users-mid'>
      <ul>
        {responses.map((response, index) => (
          <li key={index}>
            <div className='icon-btn-container'>
              <p>View results for user "{response.name}": </p>
              <button className='iconed-button' onClick={() => navigate(`/results-admin/${survey_id}/${response.user_id}`)}>
                Results<img src={info_icon}  alt='info-survey' className="icon"/>
              </button>
              {/* <button className='iconed-button' onClick={() => navigate(`/results-kolodniy/${survey_id}/${response.user_id}`)}>
                Testing<img src={info_icon}  alt='info-survey' className="icon"/>
              </button> */}
              
              <button className='iconed-button' onClick={() => deleteResponse(survey_id, response.user_id)}>
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
      </div>
    </div>
    </div>
    <BotPanel is_fixed={false}/>
    </div>
  );
};

export default SurveyPassedUsersAdmin;