import React from "react";
import { useNavigate } from "react-router-dom";
import TopPanelBase from "../Components/TopPanel/TopPanelBase";
import BotPanel from "../Components/BottomPanel/BotPanel";
import "../Auth/LoginPage.css";
import "./AboutPage.css";


const Spacer = ({ height }) => {
  return <div style={{ height: `${height}px` }} />;
};


function AboutPage() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Повернення на попередню сторінку
  };

  return (
    <div className="AboutPage">
      <TopPanelBase
        rightContent={
          <button onClick={handleBackClick} className="back-btn">
            Back
          </button>
        }
      />

      <div className="about-container">
        <h1 className="about-title" style={{ color: "red" }}>
          Hello, Soulmate!
        </h1>
        <p className="about-text">
          Як важко знайти споріднену душу! <br></br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;А як хотілося б знайти!..<br></br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Спробуйте!
        </p>
        <p className="about-text">
          Наша програма аналізує близькість емоційних реакцій різних людей на
          різноманітні стимули у вигляді тексту, картинок або відео.
        </p>
        <p className="about-text">
          Якщо Ви побачили або почули щось таке, що примусило якось по
          особливому бриніти струни Вашої душі, поділіться цим стимулом з
          іншими.
        </p>
        <p className="about-text">
          Можливо, чиясь душа відгукнеться емоційним резонансом?..
        </p>
        <p className="about-text about-highlight">
          Шукайте і знаходьте свого <span className="highlight">soulmate</span>!
        </p>
      </div>

      <BotPanel is_fixed={true} />
    </div>
  );
}

export default AboutPage;
